export const brokersData = [
    {
      rank: "1",
      name: "AMarkets",
      type: "ECN/STP",
      deposit: "$100",
      regulators: "FSC, MISA, SVGFSA",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 5,
      spread: "0.1 пункта",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:3000",
      yearFounded: 2007,
      url: "https://just-register.com/amarkets?source=spekulant",
      logo: "amarkets.png",
      description: "Подходит для активных трейдеров и новичков, ищущих быстрые исполнения ордеров.",
      advantages: ["Высокая скорость исполнения", "Множество обучающих материалов", "Кредитное плечо до 1:1000"]
    },
    {
      rank: "2",
      name: "Alpari",
      type: "ECN/STP",
      deposit: "$50",
      regulators: "MISA, НБ РБ",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 5,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:3000",
      yearFounded: 1998,
      url: "https://just-register.com/alpari?source=spekulant",
      logo: "alpari.png",
      description: "Идеально подходит для трейдеров, предпочитающих разнообразные типы счетов и аналитические инструменты.",
      advantages: ["Доступ к ECN и стандартным счетам", "Низкие комиссии и спреды", "Интуитивно понятная платформа"]
    },
    {
      rank: "3",
      name: "FxPro",
      type: "STP",
      deposit: "$100",
      regulators: "FCA, CySEC, SCB",
      platforms: "MetaTrader 4, MetaTrader 5, cTrader, FxPro Edge",
      rating: 4.8,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:10000",
      yearFounded: 2006,
      url: "https://just-register.com/fxpro?source=spekulant",
      logo: "fxpro.png",
      description: "Подходит для профессиональных трейдеров, заинтересованных в надежной торговле с узкими спредами.",
      advantages: ["Узкие спреды на основные пары", "Поддержка нескольких платформ (MT4, cTrader)", "Регулируется FCA и CySEC"]
    },
    {
      rank: "4",
      name: "InstaForex",
      type: "MM",
      deposit: "$1",
      regulators: "BVIFSC, CySEC, SVGFSA",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 4.6,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2007,
      url: "https://just-register.com/instaforex?source=spekulant",
      logo: "instaforex.png",
      description: "Отличный выбор для трейдеров, ориентированных на торговлю с низкими депозитами.",
      advantages: ["Минимальный депозит от $1", "Платформа MetaTrader 4 и 5", "Бонусы для новых клиентов"]
    },
    {
      rank: "5",
      name: "Deriv",
      type: "STP",
      deposit: "$1",
      regulators: "LFSA, BVIFSC, VFSC, SVGFSA, JFSC, MFSA",
      platforms: "MetaTrader 5, cTrader, Deriv X",
      rating: 4.4,
      spread: "0.5 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2020,
      url: "https://just-register.com/deriv?source=spekulant",
      logo: "deriv.png",
      description: "Подходит для тех, кто ищет удобную платформу для бинарных опционов и форекс-торговли.",
      advantages: ["Интуитивно понятная платформа", "Минимальные депозиты и риски", "Множество активов для торговли"]
    },
    {
      rank: "6",
      name: "xChief",
      type: "STP/ECN",
      deposit: "$10",
      regulators: "MISA",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 4.2,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2014,
      url: "https://just-register.com/forexchief?source=spekulant",
      logo: "xchief.png",
      description: "Подходит для трейдеров, предпочитающих низкие спреды и удобные торговые условия.",
      advantages: ["Низкие спреды", "Отличная аналитика и инструменты", "Доступ к MetaTrader 5"]
    },
    {
      rank: "7",
      name: "FXOpen",
      type: "ECN",
      deposit: "$1",
      regulators: "FCA, CySEC, ASIC",
      platforms: "MetaTrader 4, MetaTrader 5, TickTrader, TradingView",
      rating: 4,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:500",
      yearFounded: 2005,
      url: "https://just-register.com/fxopen?source=spekulant",
      logo: "fxopen.png",
      description: "Подходит для трейдеров, заинтересованных в криптовалютах и форекс-торговле с узкими спредами.",
      advantages: ["Торговля криптовалютами", "Узкие спреды от 0.0 пипсов", "Поддержка ECN счетов"]
    },
    {
      rank: "8",
      name: "Gerchik & Co",
      type: "STP",
      deposit: "$100",
      regulators: "VFSC",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 4,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:100",
      yearFounded: 2015,
      url: "https://just-register.com/gerchikco?source=spekulant",
      logo: "gerchikco.png",
      description: "Идеален для консервативных трейдеров, предпочитающих безопасную торговлю с минимальными рисками.",
      advantages: ["Надежная защита средств клиентов", "Доступ к аналитическим инструментам", "Минимальный депозит от $100"]
    },
    {
      rank: "9",
      name: "NPBFX",
      type: "STP",
      deposit: "$10",
      regulators: "SVGFSA, MISA",
      platforms: "MetaTrader 4",
      rating: 4,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2016,
      url: "https://just-register.com/npbfx?source=spekulant",
      logo: "npbfx.png",
      description: "Подходит для трейдеров, предпочитающих высокие кредитные плечи и автоматическую торговлю.",
      advantages: ["Кредитное плечо до 1:1000", "Поддержка советников и алгоритмов", "Прямой доступ к межбанковскому рынку"]
    },
    {
      rank: "10",
      name: "FIBO Group",
      type: "STP",
      deposit: "$1",
      regulators: "BVIFSC",
      platforms: "MetaTrader 4, MetaTrader 5, cTrader",
      rating: 3.9,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Криптовалюты",
      leverage: "1:400",
      yearFounded: 1998,
      url: "https://just-register.com/fibogroup?source=spekulant",
      logo: "fibogroup.png",
      description: "Подходит для трейдеров, ищущих брокера с долгой историей и отличными условиями для торговли.",
      advantages: ["Существует с 1998 года", "Регулируется FSC и CySEC", "Широкий выбор типов счетов"]
    },
    {
      rank: "11",
      name: "Grand Capital",
      type: "STP/ECN",
      deposit: "$100",
      regulators: "SVGFSA",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 3.9,
      spread: "0.4 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2006,
      url: "https://just-register.com/grandcapital-forex?source=spekulant",
      logo: "grandcapital.png",
      description: "Подходит для трейдеров, которые хотят работать с разнообразными торговыми активами и высокой надежностью.",
      advantages: ["Надежная репутация", "Доступ к множеству активов", "Простота в использовании платформы"]
    },
    {
      rank: "12",
      name: "Dukascopy Bank SA",
      type: "ECN",
      deposit: "$1000",
      regulators: "FINMA",
      platforms: "MetaTrader 4, JForex",
      rating: 3.8,
      spread: "0.1 пункта",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:200",
      yearFounded: 2010,
      url: "https://just-register.com/dukascopy-bank?source=spekulant",
      logo: "dukascopy-bank.png",
      description: "Подходит для профессиональных трейдеров, которые ищут регулируемого брокера с надежными платформами.",
      advantages: ["Регулируется FINMA", "Поддержка JForex", "Надежные торговые условия"]
    },
    {
      rank: "13",
      name: "TeleTrade",
      type: "STP/ECN",
      deposit: "$1",
      regulators: "SVGFSA, CySEC",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 3.6,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:500",
      yearFounded: 2000,
      url: "https://just-register.com/teletrade?source=spekulant",
      logo: "teletrade.png",
      description: "Подходит для трейдеров, предпочитающих гибкие условия с минимальными депозитами и разнообразными активами.",
      advantages: ["Минимальный депозит от $1", "Широкий выбор активов", "Регулируется CySEC"]
    },
    {
      rank: "14",
      name: "Forex4you",
      type: "MM",
      deposit: "$1",
      regulators: "BVIFSC, SVGFSA, НБ РБ",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 3.5,
      spread: "0.1 пункта",
      assets: "Форекс, Товары, Индексы, Акции",
      leverage: "1:1000",
      yearFounded: 2007,
      url: "https://just-register.com/forex4you?source=spekulant",
      logo: "forex4you.png",
      description: "Отличный выбор для трейдеров с небольшим депозитом и высокой гибкостью торговых условий.",
      advantages: ["Минимальный депозит от $1", "Высокое кредитное плечо", "Низкие спреды"]
    },
    {
      rank: "15",
      name: "BCS Markets",
      type: "MM",
      deposit: "$1",
      regulators: "SVGFSA",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 3.5,
      spread: "0.2 пункта",
      assets: "Форекс, Товары, Индексы, Акции",
      leverage: "1:200",
      yearFounded: 2006,
      url: "https://just-register.com/bks-forex?source=spekulant",
      logo: "bcsmarkets.png",
      description: "Подходит для трейдеров, которые ищут минимальные депозиты и гибкость торговых условий.",
      advantages: ["Минимальный депозит от $1", "Надежность и простота", "Широкий выбор активов"]
    },
    {
      rank: "16",
      name: "WorldForex",
      type: "MM",
      deposit: "$1",
      regulators: "SVGFSA, VFSC",
      platforms: "MetaTrader 4, MetaTrader 5",
      rating: 3.3,
      spread: "0 пунктов",
      assets: "Форекс, Товары, Индексы, Акции, Криптовалюты",
      leverage: "1:1000",
      yearFounded: 2007,
      url: "https://just-register.com/wforex?source=spekulant",
      logo: "wforex.png",
      description: "Подходит для трейдеров, ориентированных на минимальные депозиты и гибкость торговых условий.",
      advantages: ["Минимальный депозит от $1", "Широкий выбор активов", "Высокое кредитное плечо"]
    }
  ];
  