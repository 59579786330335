import React from "react";
import { brokersData } from "../data/brokersData";

const TopBrokers = () => {
  return (
    <section className="pt-16 pb-8 bg-[#f5ecdc] text-[#333]">
      <div className="max-w-[1200px] mx-auto px-4">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">
          Топ 10 лучших Форекс брокеров в России
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {brokersData.slice(0,10).map((broker, index) => (
            <div
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
            >
              {/* Broker Logo, Name, and Number */}
              <div className="flex items-center gap-4 mb-4">
                <div className="text-2xl font-extrabold text-[#726583]">{index + 1}.</div>
                <img
                  src={`/brokers-logo/${broker.logo}`}
                  alt={`${broker.name} Logo`}
                  className="h-12 w-12 object-contain"
                />
                <h3 className="text-2xl font-semibold text-[#726583]">{broker.name}</h3>
              </div>
              <p className="text-lg mb-4">{broker.description}</p>
              <ul className="list-disc pl-5 space-y-2 mb-4">
                {broker.advantages.map((advantage, i) => (
                  <li key={i} className="text-gray-700">
                    {advantage}
                  </li>
                ))}
              </ul>
              {/* Call-to-Action Button */}
              <a
                href={broker.url}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 bg-[#726583] text-white font-semibold rounded-full shadow-md transition-all duration-300 hover:bg-[#4e3b59] hover:-translate-y-1"
              >
                Открыть счет
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TopBrokers;
