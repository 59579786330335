/* eslint-disable no-octal-escape */
import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import BrokerCard from "./components/BrokerCard";
import FAQSection from "./components/FAQSection";
import Footer from "./components/Footer";
import TopBrokers from "./components/TopBrokers";
import { brokersData } from "./data/brokersData";

const App = () => {
  return (
    <div>
      <Header />
      <HeroSection />
      <main className="container mx-auto">
        {/* Broker Ranking */}
        <div className="mt-10 px-5">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">Рейтинг Форекс брокеров в России</h2>
        </div>

        {/* Broker Cards */}
        <div id="brokers" className="space-y-5">
        {brokersData.map((broker) => (
        <BrokerCard
          key={broker.rank}
          rank={broker.rank}
          name={broker.name}
          type={broker.type}
          deposit={broker.deposit}
          regulators={broker.regulators}
          platforms={broker.platforms}
          rating={broker.rating}
          spread={broker.spread}
          assets={broker.assets}
          leverage={broker.leverage}
          yearFounded={broker.yearFounded}
          url={broker.url}
          logo={broker.logo}
        />
      ))}
        </div>
        <TopBrokers />
        <section className="mt-10 px-5">
          <h2 id="types" className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">
            Типы Форекс Брокеров
          </h2>
          <p className="text-lg mb-5 text-[#333]">
            На рынке Форекс существует несколько типов брокеров, каждый из которых имеет свои особенности работы. Рассмотрим основные типы брокеров, чтобы понять, какой из них лучше соответствует вашим торговым целям:
          </p>
          <ul className="list-none p-0 mb-5 space-y-6">
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#726583]">
              <strong className="text-xl">STP Брокеры (Straight Through Processing)</strong>
              <p className="mt-2 text-lg">
                STP брокеры передают ордера клиентов напрямую своим ликвидным провайдерам, минуя посредников. Они зарабатывают на спреде и обычно предлагают плавающие спреды. Этот тип брокера обеспечивает прозрачность и быстрое исполнение сделок.
              </p>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#726583]">
              <strong className="text-xl">ECN Брокеры (Electronic Communication Network)</strong>
              <p className="mt-2 text-lg">
                ECN брокеры объединяют трейдеров напрямую с другими участниками рынка, такими как банки, фонды и другие трейдеры. Они обеспечивают доступ к лучшим доступным ценам на покупку и продажу с минимальными спредами. Торговля через ECN брокера предполагает комиссию за каждую сделку.
              </p>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#726583]">
              <strong className="text-xl">Маркет-Мейкеры</strong>
              <p className="mt-2 text-lg">
                Маркет-мейкеры сами выступают в роли второй стороны в сделке клиента. Они могут устанавливать свои котировки и предоставляют фиксированные спреды. Их доход формируется за счёт разницы между покупкой и продажей актива. Этот тип брокера подходит для трейдеров, которые предпочитают стабильность в виде фиксированных торговых условий.
              </p>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#726583]">
              <strong className="text-xl">DMA Брокеры (Direct Market Access)</strong>
              <p className="mt-2 text-lg">
                DMA брокеры предлагают прямой доступ к рынку, позволяя трейдерам получать котировки непосредственно от поставщиков ликвидности. Это даёт возможность получить лучшую цену, а также гарантирует более прозрачное исполнение сделок. DMA брокеры обычно предлагают плавающие спреды.
              </p>
            </li>
            <li className="relative pl-5 text-gray-800 before:content-['\25A0'] before:absolute before:left-0 before:text-[#726583]">
              <strong className="text-xl">Dealing Desk Брокеры (DD)</strong>
              <p className="mt-2 text-lg">
                Брокеры с дилинговым столом обрабатывают ордера внутри компании, выступая в качестве контрагента для своих клиентов. Они могут контролировать спреды и торговые условия, что делает их подходящими для новичков, желающих торговать в стабильных условиях.
              </p>
            </li>
          </ul>
        </section>
        <section className="mt-10 px-5">
          <h2 id="choose" className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">
            Как выбрать надёжного Форекс брокера
          </h2>
          <p className="text-lg mb-5 text-[#333]">
            Выбрать надёжного Форекс брокера — это первый и очень важный шаг на пути к успешной торговле. Давайте разберем, на что стоит обратить внимание, чтобы сделать правильный выбор.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Лицензия и регулирование
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Первое, на что следует обратить внимание — это наличие лицензии и регулирующих органов. Надёжные брокеры работают под контролем авторитетных финансовых регуляторов, таких как FCA (Великобритания), ASIC (Австралия), CySEC (Кипр) или ESMA (Европейский Союз). Лицензия гарантирует, что брокер соблюдает строгие стандарты, направленные на защиту ваших интересов и средств.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Условия торговли
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Изучите торговые условия, предлагаемые брокером. Это включает минимальный депозит, спреды, комиссии и кредитное плечо. Чем более прозрачны эти условия, тем выше вероятность, что брокер заинтересован в долгосрочном сотрудничестве с клиентом. Например, слишком высокое кредитное плечо может говорить о рисках, которые компания готова взять на себя, что не всегда означает надёжность.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Торговая платформа
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Убедитесь, что брокер предлагает современную и удобную торговую платформу. Самые популярные платформы — это MetaTrader 4 и MetaTrader 5, но есть и другие качественные решения. Платформа должна быть интуитивно понятной, иметь все необходимые индикаторы и инструменты для анализа рынка, а также стабильно работать без сбоев.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Отзывы и репутация
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Важно обратить внимание на репутацию брокера. Просмотрите отзывы других трейдеров на специализированных форумах и независимых сайтах. Обратите внимание на характер комментариев — если они исключительно негативные, есть повод насторожиться. Надёжный брокер может не иметь идеальной репутации, но его проблемы должны решаться в прозрачном порядке.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Поддержка клиентов
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Качественная поддержка клиентов — ещё один важный критерий. Брокер должен предоставлять возможность быстро решать вопросы через телефон, чат или электронную почту. Хорошая поддержка доступна 24/7 и помогает решить любые технические и финансовые вопросы, которые могут возникнуть у трейдера.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Сегрегация средств
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Надёжные брокеры обеспечивают разделение клиентских средств от собственных. Это означает, что деньги клиентов хранятся на отдельных банковских счетах, что минимизирует риск их утраты в случае финансовых проблем у брокера.
          </p>

          <h3 className="text-2xl font-bold text-[#726583] mt-8 mb-3">
            Демо-счет и обучение
          </h3>
          <p className="text-lg mb-5 text-[#333]">
            Прежде чем вкладывать реальные деньги, важно попробовать торговлю на демо-счёте. Надёжные брокеры предлагают демо-счета для тестирования платформы и стратегий. Также полезно, если брокер предоставляет обучающие материалы: вебинары, видеоуроки и статьи, которые помогут вам лучше разобраться в тонкостях торговли.
          </p>
        </section>
        <section id="methodology" className="mt-10 px-5">
          <h2 className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">
            Как составлен рейтинг Форекс брокеров
          </h2>
          <p className="text-lg mb-5 text-[#333]">
            Наша методология формирования рейтинга Форекс-брокеров — это результат тщательной и всесторонней оценки по более чем 100 количественным и качественным критериям. Каждый из этих аспектов имеет свою важность и взвешенно влияет на общий рейтинг брокера.
          </p>

          <ol className="list-decimal list-inside space-y-6 text-lg text-[#333]">
            <li>
              <strong>Регулирование и безопасность.</strong> Мы внимательно изучаем, насколько надежен брокер с точки зрения лицензирования и регулирования. Проверяем, соблюдаются ли все требования безопасности и как защищены средства клиентов.
            </li>

            <li>
              <strong>Отзывы клиентов.</strong> Мы анализируем отзывы реальных пользователей, чтобы понять, насколько они довольны своим брокером. Все отзывы проходят проверку на объективность, чтобы исключить возможные искажения и создать максимально честную картину.
            </li>

            <li>
              <strong>Торговые инструменты.</strong> Мы оцениваем разнообразие доступных активов — валютных пар, акций, индексов, товаров и криптовалют. Важно, чтобы брокер предлагал широкий выбор рынков, что дает трейдерам больше возможностей для торговли.
            </li>

            <li>
              <strong>Комиссии и сборы.</strong> Мы тщательно разбираем все комиссии, которые взимает брокер, включая торговые и неторговые сборы. Особое внимание уделяем прозрачности и конкурентоспособности предлагаемых тарифов.
            </li>

            <li>
              <strong>Торговые платформы.</strong> Качество и удобство использования торговых платформ напрямую влияет на опыт трейдера. Мы оцениваем, насколько функциональны платформы, есть ли дополнительные инструменты, и удобно ли торговать с мобильного устройства.
            </li>

            <li>
              <strong>Поддержка клиентов и образовательные ресурсы.</strong> Мы проверяем, насколько легко получить помощь, как работает служба поддержки, доступна ли она 24/7, и какими каналами можно воспользоваться. Также важным аспектом является наличие обучающих материалов, которые помогают трейдерам любого уровня развиваться и улучшать свои навыки.
            </li>

            <li>
              <strong>Дополнительные преимущества.</strong> Мы смотрим на такие факторы, как программы лояльности, бонусы, уникальные услуги и инновации, которые делают работу с брокером более удобной и выгодной.
            </li>
          </ol>
          <p className="text-lg mt-6 text-[#333]">
            Методология Валютного спекулянта помогает формировать объективный и надежный рейтинг Форекс-брокеров. Это позволяет трейдерам и инвесторам находить того партнера, который соответствует их требованиям и ожиданиям для успешной работы на финансовых рынках.
          </p>
        </section>
        <FAQSection />
      </main>
      <Footer />
      {/* Back to Top Button */}
      <button
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        className="fixed bottom-5 right-5 md:bottom-10 md:right-10 bg-[#726583] text-white p-3 md:p-5 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110 hover:bg-[#4e3b59]"
      >
        <svg
          className="w-4 h-4 md:w-6 md:h-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M8 4.293l6.364 6.364a.5.5 0 01-.708.708L8 5.707l-5.656 5.658a.5.5 0 11-.708-.708L8 4.293z"
          />
        </svg>
      </button>
    </div>
  );
};

export default App;
