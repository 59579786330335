import React from "react";

const HeroSection = () => {
  return (
    <section className="text-center py-16 bg-gradient-to-r from-[#ddd0c4] to-[#f5e6d1] text-[#333]" id="top">
      <div className="mx-auto max-w-[1200px] px-4">
        <h1 className="text-3xl sm:text-4xl font-extrabold text-[#5c4a72] mb-6">
          Лучшие Форекс брокеры 2024
        </h1>
        <p className="text-base sm:text-lg max-w-xl mx-auto mb-8 text-[#444]">
          «Валютный спекулянт» — ваш надежный помощник в мире торговли. Мы анализируем брокеров, чтобы предложить вам лучших партнеров для успешных финансовых сделок.
        </p>
        <a
          href="#brokers"
          className="inline-block px-6 py-3 bg-[#5c4a72] text-white font-semibold rounded-full shadow-md transition-all duration-300 hover:bg-[#3b324e] hover:-translate-y-1"
        >
          Найти своего брокера
        </a>
      </div>
    </section>
  );
};

export default HeroSection;
