// src/components/BrokerCard.jsx
import React from "react";

const BrokerCard = ({
  rank,
  name,
  type,
  deposit,
  regulators,
  platforms,
  rating,
  spread,
  assets,
  leverage,
  yearFounded,
  url,
  logo,
}) => {
  return (
    <div className="bg-gradient-to-r from-[#ece2d0] to-[#d5c7b8] p-6 rounded-lg shadow-lg flex flex-col gap-6 text-[#333]">
      {/* Broker Rank, Logo, Name, and Rating */}
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="flex items-center gap-4">
          <div className="text-2xl font-extrabold text-[#726583]">
            #{rank}
          </div>
          <img
            src={`/brokers-logo/${logo}`}
            title={name}
            alt={`${name} Logo`}
            className="h-16 w-16 rounded-full shadow-md"
          />
          <h3 className="text-2xl font-bold text-[#1a1a1a]">{name}</h3> {/* Darker text color for better contrast */}
        </div>

        {/* Rating for Mobile - moved below logo */}
        <div className="flex items-center gap-2 mt-4 md:mt-0 md:ml-4">
          <p className="text-sm text-gray-700">Рейтинг:</p> {/* Darker text color for better contrast */}
          <div className="relative bg-gray-300 w-24 h-5 rounded-full">
            <div
              className="absolute top-0 left-0 h-5 bg-[#726583] rounded-full"
              style={{ width: `${(rating / 5) * 100}%` }}
            ></div>
          </div>
          <span className="ml-2 text-lg font-semibold text-[#726583]">
            {rating.toFixed(1)}/5
          </span>
        </div>
      </div>

      {/* Broker Info - Reorganized */}
      <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        <div>
          <p className="text-sm text-gray-700">Тип брокера:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{type}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Год основания:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{yearFounded}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Максимальное кредитное плечо:</p> {/* Darker text color */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{leverage}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Минимальный депозит:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{deposit}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Минимальный спред:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{spread}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Торговые активы:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{assets}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Регуляторы:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{regulators}</p> {/* Darker text color */}
        </div>
        <div>
          <p className="text-sm text-gray-700">Торговые платформы:</p> {/* Darker text color for better contrast */}
          <p className="text-lg font-semibold text-[#1a1a1a]">{platforms}</p> {/* Darker text color */}
        </div>
      </div>

      {/* Call-to-Action Button */}
      <div className="text-center">
        <a
          href={url}
          target="_blank"
          rel="sponsored noopener noreferrer"
          className="inline-block px-6 py-3 bg-[#726583] text-white font-semibold rounded-full shadow-md transition-all duration-300 hover:bg-[#4e3b59] hover:-translate-y-1"
        >
          Открыть счет
        </a>
      </div>
    </div>
  );
};

export default BrokerCard;
