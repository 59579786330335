import React from "react";

const Footer = () => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": "https://spekulant.ru/",
    "name": "Валютный спекулянт",
    "logo": "https://spekulant.ru/logotype.png",
    "email": "info@spekulant.ru",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Moscow",
      "addressCountry": "RU"
    }
  };
  return (
    <footer className="bg-[#726583] text-white py-12 px-5">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="container mx-auto">
        {/* Main footer content */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* About Section with increased width */}
          <div className="md:col-span-2">
            <h2 id="about" className="text-xl font-extrabold mb-4">О нас</h2>
            <p className="text-sm leading-relaxed">
              «Валютный спекулянт» — ваш надежный помощник в мире торговли и финансовых рынков. Мы анализируем и предоставляем актуальные данные о лучших Форекс брокерах, помогая трейдерам найти надёжных партнёров для успешных сделок.
            </p>
          </div>

          {/* Categories Section */}
          <div>
            <h3 className="text-xl font-extrabold mb-4">Навигация</h3>
            <ul>
              <li className="mb-3">
                <a href="#brokers" className="text-white hover:underline">
                  Рейтинг брокеров
                </a>
              </li>
              <li className="mb-3">
                <a href="#choose" className="text-white hover:underline">
                  Как выбрать брокера
                </a>
              </li>
              <li className="mb-3">
                <a href="#methodology" className="text-white hover:underline">
                  Методология
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Section */}
          <div>
            <h3 id="contact" className="text-xl font-extrabold mb-4">Контакты</h3>
            <ul className="text-sm leading-relaxed">
              <li className="mb-3">
                Email:{" "}
                <a href="mailto:info@spekulant.ru" className="text-white underline hover:text-gray-200">
                  info@spekulant.ru
                </a>
              </li>
              <li className="mb-3">Адрес: Москва, Автозаводская ул., 18, 115280</li>
            </ul>
          </div>
        </div>

        {/* Logo and name */}
        <div className="flex flex-col items-center mt-10">
          <img src="/logotype.png" alt="Валютный спекулянт Logo" className="h-16 w-16 mb-2" />
          <span className="text-xl font-semibold">Валютный спекулянт</span>
        </div>

        {/* Footer bottom text */}
        <div className="mt-10 border-t border-gray-700 pt-5 text-center text-sm">
          <p>
            &copy; 2014-2024 spekulant.ru | Все права защищены. Копирование материалов разрешено только с указанием активной ссылки на первоисточник.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
