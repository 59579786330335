import React from "react";

const Header = () => {
  return (
    <header className="p-4">
      <div className="container mx-auto flex flex-col items-center md:flex-row md:justify-between">
        <div className="logo flex flex-col items-center md:flex-row mb-4 md:mb-0">
            <img
              src="/logotype.png"
              title="Валютный спекулянт"
              alt="Spekulant Logo"
              className="w-[80px] h-[80px]"
            />
          <span className="mt-2 md:mt-0 md:ml-4 text-[#726583] text-xl md:text-2xl font-extrabold text-center md:text-left">
            Валютный спекулянт
          </span>
        </div>
        <nav className="flex space-x-3 text-[#726583]">
          <a
            href="#brokers"
            className="text-sm md:text-base font-bold hover:underline"
          >
            Рейтинг
          </a>
          <a
            href="#methodology"
            className="text-sm md:text-base font-bold hover:underline"
          >
            Методология
          </a>
          <a
            href="#about"
            className="text-sm md:text-base font-bold hover:underline"
          >
            О нас
          </a>
          <a
            href="#contact"
            className="text-sm md:text-base font-bold hover:underline"
          >
            Контакты
          </a>
        </nav>
      </div>
    </header>
  );
};

export default Header;
