import React, { useState } from 'react';

const FAQSection = () => {
    const faqs = [
        {
          question: "Какой брокер Форекс лучше?",
          answer: "Лучший брокер Форекс зависит от ваших потребностей. Популярные варианты включают AMarkets, Alpari и FxPro, каждый из которых предлагает надежные условия и качественные платформы."
        },
        {
          question: "Какие брокеры Форекс работают в России?",
          answer: "В России работают такие брокеры, как AMarkets, Alpari и FxPro, а также все остальные брокеры, попавшие в рейтинг от Валютного спекулянта. Мы тщательно проверяем надежность и доступность брокера в России."
        },
        {
          question: "Какой самый лучший брокер в мире?",
          answer: "Самыми лучшими считаются IG Group, Saxo Bank, CMC Markets и Interactive Brokers благодаря их глобальной репутации, широкому выбору инструментов и надежности."
        },
        {
          question: "Какой брокер самый надежный в России?",
          answer: "Наиболее надежными брокерами в России являются Alpari, AMarkets и FxPro благодаря их хорошей репутации. Кроме того, стоит обратить внимание на брокеров с лицензией ЦБ РФ, таких как Альфа Форекс, БКС Форекс, ВТБ Форекс и Финам."
        },
        {
          question: "Какие зарубежные брокеры работают с россиянами?",
          answer: "Среди зарубежных брокеров, работающих с россиянами, можно выделить Alpari, AMarkets и FxPro."
        },
        {
          question: "Как проверить зарубежного брокера?",
          answer: "Проверьте регулирование, отзывы клиентов, наличие лицензии и условия торговли. Также протестируйте поддержку клиентов и используйте демо-счет."
        },
        {
          question: "Как определить надежность брокера?",
          answer: "Надежность брокера определяется его регулированием, репутацией, прозрачностью условий, качеством исполнения ордеров и поддержкой клиентов."
        },
        {
          question: "Как торговать на Форекс без брокера?",
          answer: "Торговля без брокера затруднена, так как брокеры обеспечивают доступ к рынку. Альтернативы включают работу через банки или P2P платформы, но они менее удобны."
        },
        {
          question: "Почему нельзя обойтись без брокера?",
          answer: "Брокер предоставляет доступ к рынку, обеспечивает ликвидность, предлагает торговые платформы и поддержку, что делает торговлю Форекс эффективной и удобной."
        },
        {
          question: "Можно ли работать с двумя брокерами?",
          answer: "Да, можно работать с двумя брокерами для диверсификации и управления рисками. Однако это требует внимательного управления счетами и ресурсами."
        }
      ];
      
    
      const jsonLd = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": faqs.map(faq => ({
          "@type": "Question",
          "name": faq.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": faq.answer
          }
        }))
      };  
  return (
    <section className="my-10 px-5 faq-section">
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <div className="mb-5">
        <h2 className="text-3xl sm:text-4xl font-extrabold text-[#726583] mb-10 text-center">
          Часто задаваемые вопросы
        </h2>
      </div>
      {/* FAQ Items */}
      {faqs.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </section>
  );
};

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-3 faq-item border-b border-gray-300 last:border-b-0">
      <h3
        className={`faq-question text-base font-bold cursor-pointer p-4 bg-gray-100 rounded transition-colors duration-300 ${
          isOpen ? 'bg-gray-200' : 'hover:bg-gray-200'
        } flex justify-between items-center`}
        onClick={() => setIsOpen(!isOpen)}
      >
        {question}
        <div className="px-1 w-4">
            <svg
            className={`w-4 h-4 transform transition-transform ${
                isOpen ? 'rotate-180' : 'rotate-0'
            }`}
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            >
            <path
                fillRule="evenodd"
                d="M1.646 4.646a.5.5 0 011 0l6.646 6.647 6.647-6.647a.5.5 0 11.708.708l-7 7a.5.5 0 01-.708 0l-7-7a.5.5 0 010-.708z"
            />
            </svg>
        </div>
      </h3>
      {isOpen && (
        <div className="faq-answer p-4 bg-white">
          <div className="text-gray-700">
            {answer}
          </div>
        </div>
      )}
    </div>
  );
};

export default FAQSection;
